

export default {
  name: "VideoWallSelectionItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
}
