
import ActionButton from "@evercam/shared/components/ActionButton"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: {
    ActionButton,
  },
  computed: {
    ...mapStores(useLayoutStore),
  },
  watch: {
    "layoutStore.isFullscreen"(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.VideoWallToggleFullscreen, {
        visible,
      })
    },
  },
  methods: {
    toggleFullscreen() {
      this.$fullscreen.toggle(this.$globalRefs.mainContainer)
    },
    openSettings() {
      this.$fullscreen.exit()
      this.$emit("open-settings")
    },
  },
}
